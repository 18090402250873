import { Movie } from "../pages/Movie";
import { Playback } from "../pages/Playback";
import { Browse } from "../pages/Browse";
import { Search } from "../pages/Search";
import { deviceId, isExternalPlatform, refreshUserData } from "./utils";
import { Settings as SettingsPage } from "../pages/Settings";
import { isPostTvPlatform, PLATFORMS, STORAGE_KEYS, storageSaveUser } from "./utils";
import { Favorite } from "../pages/Favorite";
import { Profile, Router, Storage } from "@lightningjs/sdk";
import { ErrorPage } from "../pages/ErrorPage";
import { LiveChannels } from "../pages/LiveChannels";

// import * as Sentry from "@sentry/browser";
import posthog from "posthog-js";
import { Boot } from "../pages/Boot";
import { InfoPage } from "../pages/Info";
import {
    getContentItemById,
    getDashboard,
    getStream,
    getWatchList,
    getSubscriptionData,
    getCategories,
    getBranding,
    getLiveTvChannels
} from "../api/queries";
import { Trailer } from "../pages/Trailer";
import configs from "./configs";
import { Series } from "../pages/Series";

import { Home } from "../pages/Home";
import { Parameters } from "@firebolt-js/sdk";






export default {
    boot: () => {
        return new Promise<void>((resolve) => {
            (async () => {
                const query = Router.getQueryStringParams();
                const queryPlatform = query ? query.platform : undefined;

                const userAgent = navigator.userAgent.toLowerCase();

                const isSamsung = typeof window.tizen !== "undefined";
                const isLG = /(webos|web0s)/i.test(userAgent);

                const tvPlatform = isSamsung
                    ? PLATFORMS.samsung
                    : isLG
                      ? PLATFORMS.lg
                      : userAgent.indexOf("whaletv") > 0
                        ? PLATFORMS.whaletv
                        : userAgent.indexOf("philips") > 0
                          ? PLATFORMS.philips
                          : userAgent.indexOf("nettv") > 0
                            ? PLATFORMS.nettv
                            : typeof window.Hisense_GetDeviceID === "function"
                              ? PLATFORMS.hisense
                              : undefined;

                let platform = queryPlatform || tvPlatform || (await Profile.platform());
                platform = platform.toLowerCase() === "wpe" ? "comcast" : platform;

                Storage.set(STORAGE_KEYS.platform, platform);

                if (isPostTvPlatform()) {
                    Storage.set(STORAGE_KEYS.token, query ? query.token : "");
                }

                // Sentry.init({
                //     dsn: "https://ad27022944804f0b82dafbce3ec38ac5@o599374.ingest.sentry.io/4505169061150720",
                //     integrations: [new Sentry.BrowserTracing()]
                // });

                const generatedDeviceId = await deviceId();

                // metrological is a local build
                if (platform !== "metrological" && configs.posthog) {
                    posthog.init(configs.posthog, {
                        api_host: "https://app.posthog.com",
                        autocapture: false
                    });
                    posthog.identify(generatedDeviceId, { platform: Storage.get(STORAGE_KEYS.platform) });
                    posthog.capture("AppStart");
                }

                await refreshUserData(true);

                if (!isExternalPlatform()) {
                    try {
                        const subData = await getSubscriptionData();
                        Storage.set("subscriptionData", subData);
                    } catch (e) {
                        console.log("subscriptionData err", e);
                    }
                }

                resolve();
            })();
        });
    },
    root: () => {
        return new Promise<string>((resolve) => {
            Parameters.initialization()
                .then((init) => {
                    // console.log("Parameters", init);

                    // @ts-expect-error: faulty types
                    if (init.discovery?.navigateTo?.data) {
                        // @ts-expect-error: faulty types
                        const { entityId, programType } = init.discovery?.navigateTo?.data;
                        // console.log(" entityId, programType", entityId, programType);

                        if (entityId !== "abc") {
                            resolve(`${programType === "series" ? "series" : "movie"}/${entityId}`);
                            return;
                        }
                    }

                    resolve(configs.settings.landingModule || "home");
                })
                .catch((error) => {
                    console.log(error);

                    resolve(configs.settings.landingModule || "home");
                });
        });
    },
    routes: [
        {
            path: "$",
            component: Boot
        },
        {
            path: "home",
            component: Home,
            options: {
                clearHistory: true
            },
            on: (page: any) => {
                return new Promise<void>((resolve, reject) => {
                    (async () => {
                        if (configs.modules.branding) {
                            try {
                                page.branding = await getBranding();
                            } catch (e) {}
                        }

                        try {
                            page.data = await getDashboard();

                            resolve();
                        } catch (e) {
                            reject(e);
                        }
                    })();
                });
            },
            widgets: [
                <Lowercase<string>>"Menu",
                <Lowercase<string>>"Auth",
                <Lowercase<string>>"Subscription",
                <Lowercase<string>>"IAPSubscription",
                <Lowercase<string>>"PostTvSubscription",
                <Lowercase<string>>"Exit"
            ],
            cache: 60
        },
        {
            path: "movie/:movieId",
            component: Movie,
            on: (page: any, { movieId }: any) => {
                // eslint-disable-next-line no-async-promise-executor
                return new Promise<void>(async (resolve, reject) => {
                    try {
                        page.data = await getContentItemById(movieId);

                        resolve();
                    } catch (e) {
                        reject(e);
                    }
                });
            },
            widgets: [
                // <Lowercase<string>>"Menu",
                <Lowercase<string>>"Auth",
                <Lowercase<string>>"Subscription",
                <Lowercase<string>>"IAPSubscription",
                <Lowercase<string>>"PostTvSubscription",
                <Lowercase<string>>"Exit"
            ]
        },
        {
            path: "series/:movieId",
            component: Series,
            on: (page: any, { movieId }: any) => {
                // eslint-disable-next-line no-async-promise-executor
                return new Promise<void>(async (resolve, reject) => {
                    try {
                        page.data = await getContentItemById(movieId);

                        resolve();
                    } catch (e) {
                        reject(e);
                    }
                });
            },
            widgets: [
                <Lowercase<string>>"Menu",
                <Lowercase<string>>"Auth",
                <Lowercase<string>>"Subscription",
                <Lowercase<string>>"IAPSubscription",
                <Lowercase<string>>"PostTvSubscription",
                <Lowercase<string>>"Exit"
            ]
        },
        {
            path: "playback/:movieId",
            component: Playback,
            on: (page: any, { movieId }: any) => {
                // eslint-disable-next-line no-async-promise-executor
                return new Promise<void>(async (resolve, reject) => {
                    try {
                        let data;
                        data = await getStream(movieId);
                        posthog.capture("Playback", {
                            movie_title: data.movie?.title || ""
                        });
                        page.data = data;
                        resolve();
                    } catch (e) {
                        if (navigator.onLine) {
                            await refreshUserData(true);

                            const user = Storage.get(STORAGE_KEYS.user);
                            if (!user?.subscribed) {
                                page.redirect = true;

                                resolve();
                            } else {
                                reject(e);
                            }
                        } else {
                            reject(e);
                        }
                    }
                });
            },
            cache: 60
        },,
        {
            path: "browse",
            component: Browse,
            on: (page: any) => {
                // eslint-disable-next-line no-async-promise-executor
                return new Promise<void>(async (resolve, reject) => {
                    try {
                        const data = await getCategories();
                        // if (data[0]) {
                        // const category = data[0];
                        // category.movies = await getMoviesByCategory(category.categoryId);
                        // }

                        page.data = data;

                        resolve();
                    } catch (e) {
                        console.log("e", e);
                        reject(e);
                    }
                });
            },
            widgets: [
                <Lowercase<string>>"Menu",
                <Lowercase<string>>"Auth",
                <Lowercase<string>>"Subscription",
                <Lowercase<string>>"IAPSubscription",
                <Lowercase<string>>"PostTvSubscription",
                <Lowercase<string>>"Exit"
            ],
            cache: 60
        },
        {
            path: "live",
            component: LiveChannels,
            on: (page: any) => {
                return new Promise<void>(async(resolve, reject) => {
                    try {
                        const allChannels = await getLiveTvChannels();
                        page.data = allChannels;
                        resolve();
                    } catch (e) {
                        reject(e);
                    }
                });
            },
            widgets: [
                <Lowercase<string>>"Menu",
                <Lowercase<string>>"Auth",
                <Lowercase<string>>"Subscription",
                <Lowercase<string>>"IAPSubscription",
                <Lowercase<string>>"PostTvSubscription",
                <Lowercase<string>>"Exit"
            ],
            cache: 60
        },
        {
            path: "favorite",
            component: Favorite,
            on: (page: any) => {
                // eslint-disable-next-line no-async-promise-executor
                return new Promise<void>(async (resolve, reject) => {
                    try {
                        page.data = await getWatchList();

                        resolve();
                    } catch (e) {
                        reject(e);
                    }
                });
            },
            widgets: [
                <Lowercase<string>>"Menu",
                <Lowercase<string>>"Auth",
                <Lowercase<string>>"Subscription",
                <Lowercase<string>>"IAPSubscription",
                <Lowercase<string>>"PostTvSubscription",
                <Lowercase<string>>"Exit"
            ]
        },
        {
            path: "search",
            component: Search,
            on: () => {
                return new Promise<void>((resolve) => {
                    resolve();
                });
            },
            widgets: [
                <Lowercase<string>>"Menu",
                <Lowercase<string>>"Auth",
                <Lowercase<string>>"Subscription",
                <Lowercase<string>>"IAPSubscription",
                <Lowercase<string>>"PostTvSubscription",
                <Lowercase<string>>"Exit"
            ]
        },
        {
            path: "trailer",
            component: Trailer,
            on: (page: any) => {
                // eslint-disable-next-line no-async-promise-executor
                return new Promise<void>(async (resolve, reject) => {
                    try {
                        page.data = window.selectedMovie?.trailer.url;

                        resolve();
                    } catch (e) {
                        console.log(e);
                        reject(e);
                    }
                });
            }
        },
        {
            path: "settings",
            component: SettingsPage,
            on: () => {
                return new Promise<void>((resolve) => {
                    resolve();
                });
            },
            widgets: [
                <Lowercase<string>>"Menu",
                <Lowercase<string>>"Subscription",
                <Lowercase<string>>"Auth",
                <Lowercase<string>>"IAPSubscription",
                <Lowercase<string>>"PostTvSubscription",
                <Lowercase<string>>"Exit"
            ],
            options: {
                clearHistory: true
            }
        },
        {
            path: "info",
            component: InfoPage,
            on: () => {
                return new Promise<void>((resolve) => {
                    resolve();
                });
            },
            widgets: [
                <Lowercase<string>>"Menu",
                <Lowercase<string>>"Subscription",
                <Lowercase<string>>"Auth",
                <Lowercase<string>>"IAPSubscription",
                <Lowercase<string>>"PostTvSubscription",
                <Lowercase<string>>"Exit"
            ]
        },
        {
            path: "!",
            component: ErrorPage,
            widgets: [<Lowercase<string>>"Menu", <Lowercase<string>>"Exit"],
            options: {}
        }
    ]
};
