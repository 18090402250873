import { QGLResponse } from "./types";
import { api } from "./index";
import { userFragment } from "./fragments";
import { IAPData, UserModel } from "../lib/models";
import { getPlatform, PLATFORMS } from "../lib/utils";

export const addToWatchListMutation = `
	mutation AddToWatchList($contentId: String!) {
	  addToWatchList(contentId: $contentId) {
		id
		__typename
	  }
	}
`;

export const removeFromWatchListMutation = `
	mutation RemoveWatchList($contentId: String!) {
	  removeFromWatchList(contentId: $contentId) {
		id
		__typename
	  }
	}
`;

export const updateProgressMutation = `
    mutation UpdateProgress($id: String!, $progress: Int!) {
      updateProgress(contentId: $id, progress: $progress) {
        ...on Episode {
          id
          title
          progress {
            current
          }
        }
      }
    }
`;

const amazonSubscriptionMutation = `
mutation CreateFireTvSubscription($userId: String!, $receiptId: String!, $email: String!){
    createFireTvSubscription(userId: $userId, receiptId: $receiptId, email: $email) {
        ...UserData
    }
}`;

const androidSubscriptionMutation = `
mutation CreateAndroidTvSubscription($purchaseToken: String!, $subscriptionId: String!){
    createAndroidTvSubscription(purchaseToken: $purchaseToken, subscriptionId: $subscriptionId) {
        ...UserData
    }
}`;

export const addToWatchList = async (id: string | number) => {
    const { data }: QGLResponse = await api("graphql", {
        method: "POST",
        body: { query: addToWatchListMutation, variables: { contentId: id } }
    });

    return data;
};

export const removeFromWatchList = async (id: string | number) => {
    const { data }: QGLResponse = await api("graphql", {
        method: "POST",
        body: { query: removeFromWatchListMutation, variables: { contentId: id } }
    });

    return data;
};

export const updateProgress = async (id: string | number, progress: number) => {
    const { data }: QGLResponse = await api("graphql", {
        method: "POST",
        body: { query: updateProgressMutation, variables: { id: id, progress: progress } }
    });

    return data;
};

export const createPostTvSubscription = async () => {
    const { data }: QGLResponse = await api("graphql", {
        method: "POST",
        body: {
            query: `
                mutation CreateSub{
                  createSubscription{
                     ...UserData
                  }
                },
                ${userFragment}
        `
        }
    });

    return data.createSubscription;
};

export const getPayAlteoxSubscriptionLink = async (action = "") => {
    const { data }: QGLResponse = await api("graphql", {
        method: "POST",
        body: {
            query: `
                mutation SubscriptionLink($action: String){
                  getSubscriptionLink(action: $action){
                    url
                  }
                }
        `,
            variables: { action }
        }
    });

    return data.getSubscriptionLink;
};

export const login = async (body: { email: string; password: string }) => {
    const { data, errors }: QGLResponse = await api("graphql", {
        method: "POST",
        body: {
            query: `
                mutation Login($email: String!, $password: String!){
                  loginByPassword(email: $email, password: $password){
                     ...UserData
                  }
                },
                ${userFragment}
        `,
            variables: body
        }
    });

    console.log(
        `login response ${JSON.stringify({
            data,
            errors
        })}`
    );

    return {
        data: data?.loginByPassword,
        error: errors ? errors[0] : null
    };
};

export const signup = async (body: { email: string; password: string }) => {
    const { data, errors }: QGLResponse = await api("graphql", {
        method: "POST",
        body: {
            query: `
                mutation Signup($email: String!, $password: String!){
                  signupByPassword(email: $email, password: $password){
                     ...UserData
                  }
                },
                ${userFragment}
        `,
            variables: body
        }
    });

    console.log(
        `signup response ${JSON.stringify({
            data,
            errors
        })}`
    );

    return {
        data: data?.signupByPassword,
        error: errors ? errors[0] : null
    };
};

export const logout = async () => {
    const { data }: QGLResponse = await api("graphql", {
        method: "POST",
        body: {
            query: `
                mutation Logout{
                  logout{
                     ...UserData
                  }
                },
                ${userFragment}
        `
        }
    });

    return data.logout;
};

// export const createIAPSUb = async (transactionId: string) => {
//     const { data, errors }: QGLResponse = await api("graphql", {
//         method: "POST",
//         body: {
//             query: `
//                 mutation CreateAppleSubscription($transactionId: String!){
//                   createAppleSubscription(transactionId: $transactionId){
//                      ...UserData
//                   }
//                 },
//                 ${userFragment}
//         `,
//             variables: { transactionId }
//         }
//     });
//
//     console.log(
//         `signup response ${JSON.stringify({
//             data,
//             errors
//         })}`
//     );
//
//     return data?.signupByPassword;
// };

export const syncExternalSubscription = async (body: IAPData): Promise<UserModel> => {
    console.log(`body: ${JSON.stringify(body)}`);
    console.log(`query: ${(await getPlatform()) === PLATFORMS.androidtv ? androidSubscriptionMutation : amazonSubscriptionMutation},
                ${userFragment}`);

    // @ts-ignore
    const { data, error }: QGLResponse = await api("graphql", {
        method: "POST",
        body: {
            query: `
                ${(await getPlatform()) === PLATFORMS.androidtv ? androidSubscriptionMutation : amazonSubscriptionMutation},
                ${userFragment}
        `,
            variables: body
        }
    });

    console.log(
        `response ${JSON.stringify({
            error,
            data
        })}`
    );

    if (error) {
        throw error;
    }

    return data.createAndroidTvSubscription || data.createFireTvSubscription;
};
