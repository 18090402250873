import Hls from "hls.js";
import { Router, VideoPlayer } from "@lightningjs/sdk";
import { Metrics } from "@firebolt-js/sdk";
import { Player as BitmovinPlayer, PlayerEvent } from 'bitmovin-player';
import configs from "./configs";
import { UIFactory } from 'bitmovin-player-ui';

interface VideoConfig {
    startPosition?: number;
}

interface SourceConfig {
    hls?: string;
    dash?: string;
    progressive?: string;
}

let hls: Hls | null = null;
let bitmovinPlayer: InstanceType<typeof BitmovinPlayer> | null = null;
let bitmovinPlayerUI: ReturnType<typeof UIFactory.buildDefaultUI> | null = null;

const defaults = {
    debug: false,
    autoplay: true,
    backBufferLength: 10,
    maxBufferLength: 30,
    maxBufferSize: 30
};

const determineBitmovinSourceType = (url: string): SourceConfig => {
    const sourceConfig: SourceConfig = {};

    if (url.includes('.m3u8')) {
        sourceConfig.hls = url;
    } else if (url.includes('.mpd')) {
        sourceConfig.dash = url;
    } else if (url.match(/\.(mp4|webm|mov)$/i)) {
        sourceConfig.progressive = url;
    } else {
        // Default to HLS if we can't determine the type
        sourceConfig.hls = url;
    }

    return sourceConfig;
};

const unload = (videoEl: HTMLVideoElement) => {
    if (configs.bitmovin.useBitmovin && bitmovinPlayer) {
        bitmovinPlayer.destroy();
        bitmovinPlayer = null;
    } else if (hls && hls.destroy && hls.destroy instanceof Function) {
        hls.destroy();
        hls = null;
    }
    if (videoEl) {
        videoEl.removeAttribute("src");
        videoEl.load();
    }
};

const handleUnrecoverableError = (player: Hls | InstanceType<typeof BitmovinPlayer>, errorEvent: string) => {
    if (VideoPlayer._consumer) {
        VideoPlayer._consumer.fire("$videoPlayerError", errorEvent, VideoPlayer.currentTime);
        VideoPlayer._consumer.fire("$videoPlayerEvent", "Error", errorEvent, VideoPlayer.currentTime);
    }
    if (player instanceof Hls) {
        player.destroy();
    } else if (player instanceof BitmovinPlayer) {
        player.destroy();
    }
};

const loader = (src: string, videoEl: HTMLVideoElement, config: VideoConfig = {}): Promise<void> => {

    return new Promise((resolve) => {
        unload(videoEl);

        videoEl.setAttribute("poster", "/static/images/poster.png");
        videoEl.style.setProperty("background", "#000000");

        if (configs.bitmovin.useBitmovin) {
            bitmovinPlayer = new BitmovinPlayer(videoEl, {
                key: configs.bitmovin.bitmovinPlayerKey,
                ui: false,
                analytics: {
                    key: configs.bitmovin.bitmovinAnalyticsKey,
                  }
            });
            // UIFactory.buildDefaultUI(bitmovinPlayer);

            const sourceConfig = determineBitmovinSourceType(src);
            console.log("LOAD SRC", sourceConfig);
            bitmovinPlayer.load(sourceConfig).then(() => {
                console.log("Bitmovin player loaded");
                videoEl.play()

                // if (bitmovinPlayer) {
                //     bitmovinPlayerUI = UIFactory.buildDefaultUI(bitmovinPlayer);
                // }
                // if (bitmovinPlayer) {
                //     bitmovinPlayer.play().catch(error => {
                //         console.warn('Autoplay was prevented:', error);
                //     });
                // }

                resolve();
            }).catch((error) => {
                console.error('Bitmovin player error:', error);
                if (bitmovinPlayer) {
                    handleUnrecoverableError(bitmovinPlayer, 'BITMOVIN_LOAD_ERROR');
                }
            });


            bitmovinPlayer.on(PlayerEvent.Play, () => {
                if (VideoPlayer._consumer) {
                    VideoPlayer._consumer.fire("$videoPlayerPlaying");
                }
            });

            bitmovinPlayer.on(PlayerEvent.Paused, () => {
                if (VideoPlayer._consumer) {
                    VideoPlayer._consumer.fire("$videoPlayerPause");
                }
            });

            bitmovinPlayer.on(PlayerEvent.PlaybackFinished, () => {
                if (VideoPlayer._consumer) {
                    VideoPlayer._consumer.fire("$videoPlayerEnded");
                }
            });

            bitmovinPlayer.on(PlayerEvent.Error, (error) => {
                console.error('Bitmovin player error:', error);
                handleUnrecoverableError(bitmovinPlayer!, 'BITMOVIN_PLAYBACK_ERROR');
            });

        } else if (src.indexOf(".m3u") > -1 && Hls.isSupported()) {
            hls = new Hls({ ...defaults, ...config });

            hls.on(Hls.Events.MEDIA_ATTACHED, function () {
                console.log("video and hls.js are now bound together !");
            });
            hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
                console.log("manifest loaded, found " + data.levels.length + " quality level");
                resolve();
            });

            hls.on(Hls.Events.ERROR, function (event, data) {
                console.log("ERROR", data);
                if (hls && data.fatal) {
                    switch (data.type) {
                        case Hls.ErrorTypes.NETWORK_ERROR:
                            console.log("fatal network error encountered, try to recover");
                            Metrics.error(Metrics.ErrorType.MEDIA, "NETWORK_ERROR", "playback network error", true);

                            if (navigator.onLine) {
                                hls.startLoad();
                                switch (data.details) {
                                    case Hls.ErrorDetails.FRAG_LOAD_ERROR:
                                        if (data.frag) {
                                            hls.currentLevel = data.frag.start + data.frag.duration + 0.1;
                                        } else {
                                            hls.startLoad();
                                        }
                                        break;
                                    case Hls.ErrorDetails.MANIFEST_LOAD_ERROR:
                                        handleUnrecoverableError(hls, event);
                                        break;
                                    default:
                                        hls.startLoad();
                                        break;
                                }
                            } else {
                                Router.navigate("!");
                            }
                            break;
                        case Hls.ErrorTypes.MEDIA_ERROR:
                            console.log("fatal media error encountered, try to recover");
                            Metrics.error(Metrics.ErrorType.MEDIA, "MEDIA_ERROR", "playback media error", true);
                            hls.recoverMediaError();
                            break;
                        default:
                            Metrics.error(Metrics.ErrorType.MEDIA, "MEDIA_FATAL_ERROR", "playback fatal error", true);
                            handleUnrecoverableError(hls, event);
                            break;
                    }
                }
            });

            hls.loadSource(src);
            hls.attachMedia(videoEl);
        } else {
            videoEl.setAttribute("src", src);
            videoEl.load();

            videoEl.currentTime = config.startPosition || 0;

            videoEl
                .play()
                .then(() => console.log("play"))
                .catch((e) => console.log(e));

            resolve();
        }
    });
};

const unloader = (videoEl: HTMLVideoElement): Promise<void> => {
    return new Promise((resolve) => {
        unload(videoEl);
        resolve();
    });
};

export { VideoConfig, loader, unloader };