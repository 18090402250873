import { Lightning, Router } from "@lightningjs/sdk";
//@ts-ignore
import { List } from "@lightningjs/ui";
import theme from "../../lib/theme";
import { ChannelModel, ProgramModel } from "../../lib/models";
import { ChannelItem } from "../ChannelItem/ChannelItem";

interface ChannelListSpec extends Lightning.Component.TemplateSpec {
    Container: {
        List: typeof List;
    };
}

export class ChannelList
    extends Lightning.Component<ChannelListSpec>
    implements Lightning.Component.ImplementTemplateSpec<ChannelListSpec>
{
    private _channels: ChannelModel[] = [];

    static override _template(): Lightning.Component.Template<ChannelListSpec> {
        return {
            x: 0,
            y: 0,
            w: theme.layout.screenW - theme.menu.w,
            h: theme.layout.screenH,
            flex: { direction: "column" },
            collision: true,
            zIndex: 10,
            Container: {
                h: theme.layout.screenH,
                w: 500,
                x: 0,
                y: 0,
                clipping: true,
                collision: true,
                List: {
                    type: List,
                    collision: true,
                    direction: "column",
                    spacing: 10,
                    h: theme.layout.screenH,
                    w: 500,
                    y: 0,
                    x: 0,
                    scroll: { after: 5 },
                    gcThreshold: 2,
                    requestThreshold: 4,
                    scrollTransition: {
                        duration: 0.4,
                        timingFunction: "linear"
                    },
                    enableRequests: true,
                    signals: { onIndexChanged: true, onRequestItems: true }
                }
            }
        };
    }

    readonly Container = this.getByRef("Container")!;
    readonly List = this.Container.getByRef("List")! as List;

    override _getFocused(): Lightning.Component | null | undefined {
        return this.List;
    }

    addItems(channels: ChannelModel[]): void {
        this._channels = channels;

        if (this.List.items?.length) return;

        const items = channels.map(this.createChannelItem);
        this.List.add(items);
    }

    private createChannelItem = (channel: ChannelModel, index: number) => {
        const now = new Date();
        const currentProgram = channel.programs ? this.findCurrentProgram(channel.programs, now) : undefined;
        const progress = this.calculateProgress(currentProgram, now);

        return {
            type: ChannelItem,
            item: {
                ...channel,
                channelNumber: (index + 1).toString().padStart(3, '0'),
                currentProgram,
                progress
            },
        };
    };

    private findCurrentProgram(programs: ProgramModel[] | undefined, now: Date): ProgramModel | undefined {
        return programs?.find(program => {
            const start = new Date(program.start);
            const stop = new Date(program.stop);
            return now >= start && now < stop;
        });
    }

    private calculateProgress(program: ProgramModel | undefined, now: Date): number {
        if (!program) return 0;

        const start = new Date(program.start);
        const stop = new Date(program.stop);
        const totalDuration = stop.getTime() - start.getTime();
        const elapsed = now.getTime() - start.getTime();
        return Math.round((elapsed / totalDuration) * 100);
    }

    get items() {
        return this.List.items;
    }

    get index() {
        return this.List.index;
    }

    get currentItem() {
        return this.List.currentItem;
    }

    set index(value: number) {
        this.List.setIndex(value);
    }
}