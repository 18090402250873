import { Lightning, Colors } from "@lightningjs/sdk";
import { ChannelModel , ChannelItemModel} from "../../lib/models";
import theme from "../../lib/theme";


interface ChannelItemSpec extends Lightning.Component.TemplateSpec {
    ChannelNumber: object;
    ChannelName: object;
    Logo: object;
    Time: object;
    ProgressBar: {
        Progress: object;
    };
}

export class ChannelItem extends Lightning.Component<ChannelItemSpec> {
    private _channel: ChannelItemModel | null = null;

    static override _template(): Lightning.Component.Template<ChannelItemSpec> {
        return {
            w: this.width,
            h: this.height,
            rect: true,
            color: Colors(theme.color.container).get(),
            shader: { type: Lightning.shaders.RoundedRectangle, radius: 8 },
            ChannelNumber: {
                x: 20,
                y: 15,
                text: { text: '', fontSize: 20, fontFace: 'Regular', textColor: Colors(theme.color.text).get() }
            },
            ChannelName: {
                x: 120,
                y: 15,
                w:360,
                h:50,
                text: { text: '', fontSize: 22, fontFace: 'Regular', textColor: Colors(theme.color.text).get(), wordWrap: true, maxLines: 2, lineHeight: 26  }
            },
            Logo: {
                x: 20,
                y: 70,
                w: 80,
                h: 60,
            },
            Time: {
                x: 120,
                y: 75,
                text: { text: '', fontSize: 16, fontFace: 'Regular', textColor: Colors(theme.color.textDark).get() }
            },
            ProgressBar: {
                x: 120,
                y: 100,
                w: 360,
                h: 4,
                rect: true,
                color: Colors(theme.color.progressBackground).get(),
                Progress: {
                    w: 0,
                    h: 4,
                    rect: true,
                    color: Colors(theme.color.text).get()
                }
            }
        };
    }

    set item(channel: ChannelItemModel) {
        const { currentProgram, channelNumber, name, logos, progress } = channel;
        const programTitle = currentProgram?.title ?? name;
        const programTime = currentProgram
            ? `${this.formatTime(currentProgram.start)} - ${this.formatTime(currentProgram.stop)}`
            : '';

        this.patch({
            ChannelNumber: { text: { text: channelNumber || '000' } },
            ChannelName: { text: { text: programTitle } },
            Logo: { src: logos[0] },
            Time: { text: { text: programTime } },
            ProgressBar: {
                Progress: { w: 360 * (progress / 100) }
            }
        });

        this._channel = channel;
    }

    private formatTime(dateString: string): string {
        return new Date(dateString).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }

    override _focus() {
        this.patch({ smooth: { color: Colors(theme.color.focus).get(), } });
        this._channel && this.fireAncestors("$onChannelFocus", this._channel);
    }

    override _unfocus() {
        this.patch({ smooth: { color: Colors(theme.color.container).get(), } });
    }

    static get width() {
        return 500;
    }

    static get height() {
        return 140;
    }

    override _handleEnter() {
        this._channel && this.fireAncestors("$liveChannelSelected", this._channel);
    }
}