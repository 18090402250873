import { Lightning, Router } from "@lightningjs/sdk";
import { ChannelList } from "../components/ChannelList/ChannelList";
import { PAGES } from "../lib/utils";
import theme from "../lib/theme";
import { ChannelItemModel, ChannelModel } from "../lib/models";
import { LiveVideoPlayer } from "../components/LiveVideoPlayer/LiveVideoPlayer";
import { ChannelOverlay } from "../components/ChannelOverlay/ChannelOverlay";

interface LiveChannelsTemplateSpec extends Lightning.Component.TemplateSpec {
    List: typeof ChannelList;
    VideoPlayer: typeof LiveVideoPlayer;
    ChannelOverlay: typeof ChannelOverlay;
}

interface LiveChannelsTypeConfig extends Lightning.Component.TypeConfig {
    IsPage: true;
}

export class LiveChannels
    extends Lightning.Component<LiveChannelsTemplateSpec, LiveChannelsTypeConfig>
    implements Lightning.Component.ImplementTemplateSpec<LiveChannelsTemplateSpec>
{
    _showingList = true;
    _overlayTimeout: number | null = null;

    override _handleLeft() {
        return this.handleNavigation(-1);
    }

    override _handleRight() {
        return this.handleNavigation(1);
    }

    override _handleUp() {
        return this.toggleList();
    }

    private handleNavigation(direction: number): boolean {
        if (!this._showingList) {
            this.List.index += direction;
            const channel = this.List.items[this.List.index].item;
            this._playChannel(channel);
            this.showOverlay(channel);
            return true;
        }
        return false;
    }

    private showOverlay(channel: ChannelItemModel) {
        this.ChannelOverlay.channel = channel;
        this.ChannelOverlay.patch({ smooth: { alpha: 1 } });

        if (this._overlayTimeout) {
            clearTimeout(this._overlayTimeout);
        }

        this._overlayTimeout = setTimeout(() => {
            this.ChannelOverlay.patch({ smooth: { alpha: 0 } });
        }, 4000) as unknown as number;
    }


    override _handleDown() {
        return this.toggleList();
    }

    private toggleList(): boolean {
        if (!this._showingList) {
            this._showList();
        }
        return this._showingList;
    }



    static override _template(): Lightning.Component.Template<LiveChannelsTemplateSpec> {
        return {
            w: theme.layout.screenW,
            h: theme.layout.screenH,
            VideoPlayer: {
                type: LiveVideoPlayer,
                alpha: 1,
                zIndex: 1
            },
            List: {
                type: ChannelList,
                x: theme.menu.w + 20,
                y: 40,
                w: theme.layout.screenW,
                h: theme.layout.screenH-40,
                zIndex: 2
            },
            ChannelOverlay: {
                type: ChannelOverlay,
                alpha: 0,
                zIndex: 3
            }
        };
    }

    readonly List = this.getByRef("List")!;
    readonly VideoPlayer = this.getByRef("VideoPlayer")!;
    readonly ChannelOverlay = this.getByRef("ChannelOverlay")!;

    override _getFocused(): Lightning.Component | null | undefined {
        return this._showingList ? this.List : this.VideoPlayer;
    }

    override _firstActive() {
        this.application.emit("setActivePage", PAGES.live.name);
    }


    override _active() {
        if (this.List.items.length > 0) {
            this._playChannel(this.List.items[0].item);
        }
    }

    override _handleBack() {
        if (!this._showingList) {
            this._showList();
            return true;
        }
        if (!Router.getHistory().length) return true;
        Router.go(Router.getHistory().length * -1);
    }

    override _inactive() {
        this.stage.gc();
    }

    set data(value: ChannelModel[]) {
        this.List.addItems(value);
    }

    $liveChannelSelected(channel: ChannelItemModel) {
        this.application.emit("hideMenu");
        this._hideList();
        this._playChannel(channel)
    }

    private _playChannel(channel: ChannelItemModel) {
        if (!this.VideoPlayer.isChannelPlaying(channel)) {
            this.VideoPlayer.channel = channel;
        }
    }

    private _showList() {
        this.application.emit("showMenu");
        this._showingList = true;
        this.List.patch({ smooth: { alpha: 1 } });
        this._refocus();
    }

    private _hideList() {
        this._showingList = false;
        this.List.patch({ smooth: { alpha: 0 } });
    }

}