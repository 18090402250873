import { Lightning, Colors } from "@lightningjs/sdk";
import { ChannelItemModel } from "../../lib/models";
import theme from "../../lib/theme";

interface ChannelOverlaySpec extends Lightning.Component.TemplateSpec {
    Logo: object;
    ChannelName: object;
    ProgramName: object;
    Description: object;
    MoreInfoButton: object;
}

export class ChannelOverlay extends Lightning.Component<ChannelOverlaySpec> {
    static override _template(): Lightning.Component.Template<ChannelOverlaySpec> {
        return {
            w: 1920,
            h: 1080,
            rect: true,
            color: Colors(theme.color.overlay).alpha(0.8).get(),
            Logo: {
                x: 96,
                y: 800,
                w: 100,
                h: 50,
            },
            ChannelName: {
                x: 96,
                y: 860,
                text: { text: '', fontSize: 24, fontFace: 'Regular', textColor: Colors(theme.color.text).get() }
            },
            ProgramName: {
                x: 96,
                y: 890,
                text: { text: '', fontSize: 36, fontFace: 'Bold', textColor:Colors(theme.color.text).get() }
            },
            Description: {
                x: 96,
                y: 940,
                w: 800,
                text: { text: '', fontSize: 20, fontFace: 'Regular', textColor: Colors(theme.color.text).get(), wordWrap: true, maxLines: 2 }
            },
            // MoreInfoButton: {
            //     x: 96,
            //     y: 1000,
            //     text: { text: 'More info', fontSize: 20, fontFace: 'Regular', textColor: Colors(theme.color.text).get() },
            //     rect: true,
            //     color: 0x80808080,
            //     // padding: 10,
            // },
        };
    }

    set channel(channel: ChannelItemModel) {
        const { currentProgram, name, logos } = channel;
        this.patch({
            Logo: { src: logos[0] },
            ChannelName: { text: { text: name } },
            ProgramName: { text: { text: currentProgram?.title || '' } },
            Description: { text: { text: currentProgram?.description || '' } }
        });
    }

    // _handleEnter() {
    //     if (this.tag('MoreInfoButton').active) {
    //         // Handle More Info button click
    //     }
    // }

    // _getFocused() {
    //     return this.tag('MoreInfoButton');
    // }
}